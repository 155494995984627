// In every file you need this!

import $ from 'jquery';
//attach jquery to dom
window.jQuery = $;
window.$ = $;

import 'owl.carousel';


if ($(".products_carousel")[0]) {
    $(document).ready(function () {
        var carousel = $(".products_carousel .owl-carousel");
        carousel.owlCarousel({
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            loop: true,
            dots: true,
            nav: false,
            responsiveClass: true,
            responsive: {
            0: {
                items: 1
            },
            767: {
                items: 2
            },
            992: { 
                items: 4
            },
            1025: {
                items: 5
            }
          }
        });
        $(".products_carousel .owl_nav_products_carousel").on("click", function() {
        if ($(this).hasClass("prev")) {
                $(".products_carousel .owl_nav_products_carousel .owl-nav button.owl-prev").trigger("click");
                //console.log("clicked pre");
            } else if ($(this).hasClass("next")) {
                $(".products_carousel .owl_nav_products_carousel .owl-nav button.owl-next").trigger("click");
                //console.log("clicked next");
            }
        });
    });
}



if ($(".banner_slider")[0]) {
    $(document).ready(function () {
        var carousel = $(".banner_slider .owl-carousel");
        carousel.owlCarousel({
            autoplay: true,
            autoplayTimeout: 6000,
            autoplayHoverPause: true,
            loop: true,
            dots: true,
            items: 1
        });
    });
}



// Gallery carousel with custom navigation
// if ($(".testimonials_carousel")[0]) {
// 	$(document).ready(function() {
// 		var carousel = $(".testimonials_carousel .owl-carousel");
// 		carousel.owlCarousel({
// 		  loop: true,
// 		  nav: true,
// 		  items: 1
// 		});
// 	});

// 	$(".owl_nav_team_carousel").on("click", function() {
// 		if ($(this).hasClass("prev")) {
// 			$(".testimonials_carousel button.owl-prev").trigger("click");
// 		} else if ($(this).hasClass("next")) {
// 			$(".testimonials_carousel button.owl-next").trigger("click");
// 		}
// 	});
// }

