// UI INDEX
// import './expand_areas.js';
// import './expand_form.js';
import './google_maps.js';
// import './gravity_number_list.js';
// import './scroll_to.js';
// import './slide_blocks.js';
// import './testimonial_slider.js';
 import './smoothscroll.js';
// import './image_hover.js';
 import './scroll_to_top.js';
// // import './play_video.js';
 import './slide_menu.js';
// import './classie.js';
// import './sticky_header.js';
 import './jarallax.js';
 import './owl_carousel.js';
// import './polyfill.js';
// import './slider_timer.js';
 import './custom.js'; 
 
