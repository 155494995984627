// In every file you need this!


import $ from 'jquery';
//attach jquery to dom
window.jQuery = $;
window.$ = $;



// $( ".job_application_wrapper .gform_footer" ).prepend( "<p>Please make sure all information is correct and that all fields marked with an * are completed before submitting your application.</p>" );


// $(document).ready(function() {
//     if ($("body").hasClass("page-template-page-job-application")) {
//         var referrer_page_url = $(location).attr('href');

//         var page_title = referrer_page_url.split('?job_position=')[1];
//         var page_title = page_title.replace(/\%20/g, ' ');
//         // g' = "global"
//         //console.log(page_title);

//         $("#input_3_1").val(page_title); 
//     }
// });

// Navigation Drop Down

// $(".menu_slide_toggle").on("click", function() {
// 	if ($(".slide_down_menu").hasClass("active")) {
// 		$(".slide_down_menu").removeClass("active");
// 		$(this).text("Open Menu");
// 	} else {
// 		$(".slide_down_menu").addClass("active");
// 		$(this).text("Close Menu");
// 	}
// });


jQuery('.scroll_to').click(function(e){
    var jump = $(this).attr('href');
    var new_position = $(jump).offset();
    $('html, body').stop().animate({ scrollTop: new_position.top }, 500);
    e.preventDefault();
});

// Show Global Block Hide Sections

$(".btn_label").on("click", function() {
  var class_to_show = $(this).attr("data-attr");

  $(".btn_label").removeClass("active");
  $(this).addClass("active");

  $(".content_outer").removeClass("active");
  $("." + class_to_show).addClass("active");
  
}); 

// $(".close_btn").on("click", function() {
//   $(".solutions_content_single").hide();
//   $(".learmore_btn").removeClass("active_btn");
// }); 

// Show Single Services Pages Hide Sections

// $(".other_learmore_btn").on("click", function() {
//   var new_class_to_show = $(this).attr("data-attr");

//   $(".other_learmore_btn").removeClass("active_btn");
//   $(this).addClass("active_btn");

//   //console.log(class_to_show );
//   $(".new_solutions_content_single").hide();
//   $("." + new_class_to_show).show();
// }); 

// $(".other_close_btn").on("click", function() {
//   $(".new_solutions_content_single").hide();
//   $(".other_learmore_btn").removeClass("active_btn");
// });  

// Team Hovers

// $( ".team_member" ).hover(
//   function() {
//     $(".the_excerpt", this).addClass( "hover" );
//     $(".the_excerpt p", this).addClass( "slideInUp" );
//     $(".green_btn", this).addClass( "slideInUp" );
//   }, function() {
//     $(".the_excerpt", this).removeClass( "hover" );
//     $(".the_excerpt p", this).removeClass( "slideInUp" );
//     $(".green_btn", this).removeClass( "slideInUp" );
//   }
// );

// Scroll Follow


// Mouse Over Show Descripstions

// $('#icons a').hover( function() {
//    $(".child_links_description").find(".hover-text").css("display", "none"); 
//    $('.hover-text').eq($('#icons a').index($(this))).show().addClass("fadeIn");
//  });


// $( document ).ready(function() {
//     $(".child_links_description", this).find(".hover-text").first().css("display", "block");
// });




