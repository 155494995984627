// In every file you need this!
import $ from 'jquery';
//attach jquery to dom
window.jQuery = $;
window.$ = $;

import {
    jarallax,
    jarallaxElement,
    jarallaxVideo
} from 'jarallax';

jarallaxVideo();
jarallaxElement();

jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.2
});
