// In every file you need this!

import $ from 'jquery';
//attach jquery to dom
window.jQuery = $;
window.$ = $;


// New Menu Burger Menu

var Menu = {
  
  el: {
    ham: $('.burger_menu'),
    menuTop: $('.menu-top'),
    menuMiddle: $('.menu-middle'),
    menuBottom: $('.menu-bottom')
  },
  
  init: function() {
    Menu.bindUIactions();
  },
  
  bindUIactions: function() {
    Menu.el.ham
        .on(
          'click',
        function(event) {
        Menu.activateMenu(event);
        event.preventDefault();
      }
    );
  },
  
  activateMenu: function() {
    Menu.el.ham.toggleClass('menu_close');
    Menu.el.menuTop.toggleClass('menu-top-click');
    Menu.el.menuMiddle.toggleClass('menu-middle-click');
    Menu.el.menuBottom.toggleClass('menu-bottom-click');
    $('.mobile_menu').toggleClass('slide_open');
  }
};

Menu.init();



$(".parent_link_items").click(function() {
  $(".parent_link_items").removeClass("parent_open");
  $(this).addClass("parent_open");
  $(".dropdown_outer").removeClass("dropdown_active");
  $(".dropdown_outer").removeClass("fadeIn");
  $(this).find(".dropdown_outer").addClass("dropdown_active");
  $(this).find(".dropdown_outer").addClass("fadeIn");
});



// Nav Menu Burger Menu

// $('.nav-icon1').click(function() {
//   $('.main_nav').toggleClass('menuOpen');
//   $(this).toggleClass('open');
// });

// $("#primary-menu > li.menu-item-has-children").click(function() {
//   // $("li.menu-item-has-children").removeClass("child_active");
//   // $(".sub-menu").removeClass("sub_menu_active");
//   // $(".sub-menu").removeClass("fadeIn");

//   $(this).toggleClass("child_active");
//   $(this).find(".sub-menu").toggleClass("sub_menu_active");
//   $(this).find(".sub-menu").toggleClass("fadeIn");
// });



// Side Menu 
// var menuLeft = document.getElementById('cbp-spmenu-s1'),
//   menuRight = document.getElementById('cbp-spmenu-s2'),
//   menuTop = document.getElementById('cbp-spmenu-s3'),
//   menuBottom = document.getElementById('cbp-spmenu-s4'),
//   showLeft = document.getElementById('showLeft'),
//   showRight = document.getElementById('showRight'),
//   showTop = document.getElementById('showTop'),
//   showBottom = document.getElementById('showBottom'),
//   showLeftPush = document.getElementById('showLeftPush'),
//   showRightPush = document.getElementById('showRightPush'),
//   body = document.body;

// showLeft.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuLeft, 'cbp-spmenu-open' );
//   disableOther( 'showLeft' );
// };
// showRight.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuRight, 'cbp-spmenu-open' );
//   disableOther( 'showRight' );
// };
// showTop.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuTop, 'cbp-spmenu-open' );
//   disableOther( 'showTop' );
// };
// showBottom.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( menuBottom, 'cbp-spmenu-open' );
//   disableOther( 'showBottom' );
// };
// showLeftPush.onclick = function() {
//   classie.toggle(this, 'active');
//   classie.toggle(body, 'cbp-spmenu-push-toright');
//   classie.toggle(menuLeft, 'cbp-spmenu-open');
//   // disableOther( 'showLeftPush' );
// };
// showRightPush.onclick = function() {
//   classie.toggle( this, 'active' );
//   classie.toggle( body, 'cbp-spmenu-push-toleft' );
//   classie.toggle( menuRight, 'cbp-spmenu-open' );
//   disableOther( 'showRightPush' );
// };

// function disableOther( button ) {
//   if( button !== 'showLeft' ) {
//     classie.toggle( showLeft, 'disabled' );
//   }
//   if( button !== 'showRight' ) {
//     classie.toggle( showRight, 'disabled' );
//   }
//   if( button !== 'showTop' ) {
//     classie.toggle( showTop, 'disabled' );
//   }
//   if( button !== 'showBottom' ) {
//     classie.toggle( showBottom, 'disabled' );
//   }
//   if( button !== 'showLeftPush' ) {
//     classie.toggle( showLeftPush, 'disabled' );
//   }
//   if( button !== 'showRightPush' ) {
//     classie.toggle( showRightPush, 'disabled' );
//   }
// }